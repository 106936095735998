import React from "react";

export default function WarningFooter() {
  return (
    <footer id="footer" className="warning">
      <p>Do not navigate to any of the question links</p>
      <p>
        I have no control over the domains and cannot guarantee they will not be
        malicious.
      </p>
    </footer>
  );
}
