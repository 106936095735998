import React from "react";

export default function ResearchFooter() {
  return (
    <footer id="footer">
      Based on{" "}
      <a href="https://groups.inf.ed.ac.uk/tulips/papers/albakry2020.pdf">
        research
      </a>
      &nbsp; by the University of Edinburgh's{" "}
      <a href="https://groups.inf.ed.ac.uk/tulips/">TULiPS Research Group</a>.
    </footer>
  );
}
